'use client';

import { LOCALE_TO_PHONE_NUMBER } from '@nrdy-marketing-engine/organic/app/helpers/i18n';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const COOKIE_NAME = 'sticky_phone_number_';
const DEFAULT = '8888880446';

function killCrossCountryPhoneNumber(intlPrefix: string) {
  const cookie = Cookies.get(COOKIE_NAME);
  const newNumber = LOCALE_TO_PHONE_NUMBER[intlPrefix] || DEFAULT;

  if (!cookie || cookie === newNumber) return;
  Cookies.remove(COOKIE_NAME);
  Cookies.set(COOKIE_NAME, newNumber, { expires: 365 });
}

export default function StickyPhoneNumber({
  intlPrefix = '',
  phoneNumber = DEFAULT,
}: {
  intlPrefix?: string;
  phoneNumber?: string;
}) {
  useEffect(() => {
    killCrossCountryPhoneNumber(intlPrefix);
    if (!Cookies.get(COOKIE_NAME)) Cookies.set(COOKIE_NAME, phoneNumber, { expires: 365 });
  }, [phoneNumber]);

  return null;
}
